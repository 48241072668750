import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week starts today!`}</em></p>
    <p>{`Front Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Power Clean 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 15.4`}</strong></p>
    <p>{`Complete as many reps as possible in 8:00 of:`}</p>
    <p>{`3-HSPU’s (Master over 55=Push Press 95/65)`}</p>
    <p>{`3-Cleans (RX=185/125, Masters over 55=115/75)`}</p>
    <p>{`6-HSPU’s`}</p>
    <p>{`3-Cleans`}</p>
    <p>{`9-HSPU’s`}</p>
    <p>{`3-Cleans`}</p>
    <p>{`12-HSPU’s`}</p>
    <p>{`6-Cleans`}</p>
    <p>{`15-HSPU’s`}</p>
    <p>{`6-Cleans`}</p>
    <p>{`18-HSPU’s`}</p>
    <p>{`6-Cleans`}</p>
    <p>{`21-HSPU’s`}</p>
    <p>{`9-Cleans`}</p>
    <p>{`Etc, adding 3 reps to the HSPU’s each round and 3 reps to the cleans
every 3 rounds.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Scaled 15.4`}</em></strong></p>
    <p>{`Complete as many rounds and reps as possible in 8:00 of:`}</p>
    <p>{`10-Push Press`}</p>
    <p>{`10-Cleans`}</p>
    <p>{`(Push Press=95/65, Clean=115/75)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free Crossfit Class at 9:00am and 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Register now at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      